<template>
    <main style="margin-top: 76px;">
      
      <input type="hidden" id="seconds" value="6">
          <div class="container path pt-3 mb-4">
              <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                          <a class="text-decoration-none text-morado fw-medium" @click="goHome()" style="cursor: pointer;">
                              <i class="fas fa-home-alt text-morado"></i> Inicio</a>
                      </li>
                      <li class="breadcrumb-item">
                          <a class="text-decoration-none text-morado fw-medium" @click="goBolsa()" style="cursor: pointer;">Mi Cuenta</a>
                      </li>
                      
                      <li class="breadcrumb-item active fw-medium" aria-current="page">Verificación de pagos</li>
                  </ol>
              </nav>
          </div>
          <hr>
          
          <section class="container mb-4">
              <div class="row">
                  <div class="col-lg-8 mb-4">
                      <h2>Verificación de pagos</h2>
                      <div class="d-flex justify-content-between align-items-end">
                      </div>
                  </div>
  
                  
                  <!-- AREA DE SELECCION-->
                  <div class="col-12 col-md-12 col-lg-12">
                      <CLoader :pConfigLoad="ConfigLoad">
                          <template slot="BodyFormLoad">
                              
  
                              <div v-if="bndBanderaPago" class="products-added-list">
                                  <br>
                                  <div class="text-center">
                                      <h2 class=" mb-02 text-success mb-4">¡Pago Exitoso!</h2>
                                      <p>¡Agradecemos tu elección!, te informamos que tu pago ha sido completado exitosamente.</p>
                                      <p>Nos encontramos siempre mejorando para ofrecerte las mejores expreciencias. 
                                          <br> Para cualquier duda o aclaracion no dudes en ponerte en contacto con el equipo de Zumbashopmx.
                                      
                                      </p>
  
                                      <br>
                                      <br>
  
                                      <button @click="golistEventos()" type="button" class="btn btn-primary mb-4">
                                          Ir a mis eventos
                                      </button>
                                              
                                  </div>
                                  <br>
                              </div>
  
                              <div v-if="!bndBanderaPago" class="products-added-list">
                                  <br>
                                  <div class="text-center">
                                      <h2 class=" mb-02 text-danger mb-4">¡Error de Pago!</h2>
                                     
                                      <p>Estimado Cliente, Se produjo un error de pago.</p>
                                      <p>Los motivos de error pueden deberse a que la transacción fue rechazada o un problema al momento de verificar la operación. 
                                          <br>Te sugerimos comunícate con la entidad emisora de la tarjeta o con el equipo de Zumbashopmx para tener mas información
                                      </p>
                                      <p>
                                          Tu pedido aun se encuentra como pendiente en tu historia de pedidos.
                                      </p>
                                      
                                      <br>
                                      <br>
                                      <button @click="golistEventos()" type="button" class="btn btn-primary mb-4">
                                            Ir a mis eventos
                                      </button>
                                              
                                  </div>
                                  <br>
                              </div>
                              
                              <br>
                              <br>
                              <br>
                          </template>
                      </CLoader>
                      
                  </div>
  
              </div>
          </section>
    </main>
  </template>
  
<script>
    import CLoader from '@/components/CLoader.vue';
    export default {
        name: 'ResultadoPago',
        components:{
            CLoader
        },
        
        data() {
            return {
                ConfigLoad:{
                    ShowLoader: true,
                    ClassLoad: false,
                },
                loadingBtn: false,
                objPedido:{
                    IdMetodoEnvio: 0,
                    IdCuponDescuento: 0,
                    IdDescMayorista: 0,
                    IdDireccionEnvio: 0,
                    
                    detallePedido:[],
                    Subtotal: 0,
                    Descuento: 0, // VALOR DESCUENTO
                    CostoEnvio: 0,
                    GranTotal: 0
                },
                keyPay: '',
                bndBanderaPago: false,
            };
        },
  
        created(){
            console.log(this.$getParameterByName('id'));
        },
  
        mounted() {
            this.bus.$emit('cambiaLogoNegro');
            window.scrollTo(0, 0);
    
            this.keyPay   = this.$getParameterByName('id');
    
            if(this.keyPay  !== '') {
                this.ConfigLoad.ShowLoader = true;
                setTimeout(() => {this.verificaPago()},950);
            }else {
                console.error('No se recibio parametros: '+this.keyPay);
            }
        },  
  
        methods: {
            async verificaPago() {
    
                this.ConfigLoad.ShowLoader = true;
    
                let newRequest = {
                    Key: this.keyPay,
                    IdEvento: 0,
                    TipoVerificacion: 1
                };
    
                await this.$http.post('checkcompraszin', newRequest)
                .then( (res) => {
                
                    if(res.data.status && parseInt(res.data.ECode) === 4) {
                        //this.pasoSiguiente(2);
                        this.bndBanderaPago = false;
                        this.$toast.error('Ah ocurrido un error, no se ha podido verificar el pago');
    
                    }else if (res.data.status && parseInt(res.data.ECode) === 3) {
                        
                        //this.objVerificacionCobro = res.data.data;
                        this.$toast.success('Tu pago ha sido realizado con exito');
                        //this.pasoSiguiente(1);
                        this.bndBanderaPago = true;
                        
    
                    }else if(!res.data.status && res.data.ECode === 1) {
                        // si el pedido no se ha completado o si se completo fuera de tiempo.
                        this.$toast.info(res.data.message);
    
                        if(res.data.data.urlPasarela && res.data.data.urlPasarela != undefined){
    
                            let urlPasarela = res.data.data.urlPasarela;
                            console.log('manda paso 4');
    
                        }else {
                            this.$toast.error('Ah ocurrido un error, no se ha podido continuar con el proceso de pago.');
                            //this.pasoSiguiente(2);
                            this.bndBanderaPago = false;
                        }
                        
                    }else if(!res.data.status && res.data.ECode === 2) {
                        //this.pasoSiguiente(2);
                        this.bndBanderaPago = false;
                        this.$toast.danger(res.data.message);
                    }
    
                    
                }).catch(err => {
                    //this.pasoSiguiente(2);
                    this.bndBanderaPago = false;
                    this.$toast.error('Ah ocurrido un error, no se ha podido verificar el pago');
                    
    
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader = false;
                    
                    
                });
    
            },
    
            golistEventos(){
                this.$router.push({name:'profile',params:{pOrigen:'pagoEvento'} });
            },
        },
  };
  </script>