<template>
  <main style="margin-top: 76px;">

    <div class="container path pt-3 mb-4">
        <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a class="text-decoration-none text-morado fw-medium" @click="goHome()" style="cursor: pointer;">
                        <i class="fas fa-home-alt text-morado"></i> Inicio</a>
                </li>
                <li class="breadcrumb-item active fw-medium" aria-current="page">Mi Cuenta</li>
            </ol>
        </nav>
    </div>
    <hr>
    <div class="page-content pt-150 pb-150 mb-4">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 m-auto">
                    <div class="row">
                        <div class="col-md-3 mb-2">
                            <div class="dashboard-menu">
                                <ul class="nav flex-column" role="tablist" id="tab-profile">
                                    <li class="nav-item">
                                        <a @click="showTab(1)" class="nav-link" id="tab-1" data-bs-toggle="tab" href="#tab-contenido-1" role="tab" aria-controls="tab-contenido-1" aria-selected="true">
                                            <i class="fas fa-list"></i> Dashboard
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="tab-2" @click="getPedidos()" data-bs-toggle="tab" href="#tab-contenido-2" role="tab" aria-controls="tab-contenido-2" aria-selected="false">
                                            <i class="fas fa-shopping-bag"></i> Pedidos
                                        </a>
                                    </li>
                                    <!--<li class="nav-item">
                                        <a @click="showTab(6)" class="nav-link" id="tab-3" data-bs-toggle="tab" href="#tab-contenido-3" role="tab" aria-controls="tab-contenido-3" aria-selected="false">
                                            <i class="fas fa-heart"></i> Favoritos
                                            
                                        </a>
                                    </li> -->
                                    <li class="nav-item">
                                        <a @click="verListDirecciones()" class="nav-link" id="tab-3" data-bs-toggle="tab" href="#tab-contenido-3" role="tab" aria-controls="tab-contenido-3" aria-selected="false">
                                            <i class="fas fa-map-marker-alt"></i> Direcciones</a>
                                    </li>
                                    <li class="nav-item">
                                        <a @click="showTab(4)" class="nav-link" id="tab-4" data-bs-toggle="tab" href="#tab-contenido-4" role="tab" aria-controls="tab-contenido-4" aria-selected="false">
                                            <i class="fas fa-clipboard-check"></i> Facturación </a>
                                    </li>
                                    <li class="nav-item">
                                        <a @click="showTab(5)" class="nav-link" id="tab-5" data-bs-toggle="tab" href="#tab-contenido-5" role="tab" aria-controls="tab-contenido-5" aria-selected="false">
                                            <i class="fas fa-user-cog"></i> Datos de tu cuenta</a>
                                    </li>
                                    <li class="nav-item">
                                        <a @click="showTab(6)" class="nav-link" id="tab-6" data-bs-toggle="tab" href="#tab-contenido-6" role="tab" aria-controls="tab-contenido-6" aria-selected="false">
                                            <i class="fas fa-address-card"></i> Membresia ZIN
                                        </a>
                                    </li> 
                                    <li class="nav-item">
                                        <a @click="getEventos()" class="nav-link" id="tab-7" data-bs-toggle="tab" href="#tab-contenido-7" role="tab" aria-controls="tab-contenido-7" aria-selected="false">
                                            <i class="fas fa-calendar-star"></i> Mis Eventos
                                        </a>
                                    </li> 
                                    <li class="nav-item">
                                        <a class="nav-link" @click="cerrarSesion()" style="cursor: pointer;"><i class="fi-rs-sign-out mr-10"></i>
                                            <i class="fas fa-sign-out-alt"></i> Cerrar sesión</a>
                                    </li>

                                    
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="tab-content account dashboard-content pl-50">

                                <!-- BIENVENIDA AL PANEL DE CUENTA -->
                                <div class="tab-pane fade" id="tab-contenido-1" role="tabpanel" aria-labelledby="tab-contenido-1">
                                    <div class="card">
                                        <div class="card-header">
                                            <h3 class="mb-0">Hola {{usuario.Nombre}}!</h3>
                                        </div>
                                        <div class="card-body">
                                            <p>
                                                Desde el panel de control de tu cuenta, Puedes verificar y ver tus <a class="text-morado" @click="getPedidos()" style="cursor: pointer;">pedidos recientes</a>,<br>
                                                administrar tus <a class="text-morado" @click="verListDirecciones()" style="cursor: pointer;">direcciones para envios</a>, tus datos fiscales o 
                                                <a class="text-morado" @click="showTab(5)" style="cursor: pointer;">editar los datos de tu cuenta.</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <!-- HISTORIAL DE PEDIDOS -->
                                <div class="tab-pane fade" id="tab-contenido-2" role="tabpanel" aria-labelledby="orders-tab">
                                    <div class="card">
                                        <div class="card-header">
                                            <h3 class="mb-0">Pedidos</h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="table-responsive">

                                                <CLoader :pConfigLoad="ConfigLoadPedidos">
				                                    <template slot="BodyFormLoad">
                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>#Folio</th>
                                                                    <th>Fecha</th>
                                                                    <th>Estatus</th>
                                                                    <th>E. Pago</th>
                                                                    <th>Total</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(item,index) in listaPedidos" :key="index">
                                                                    <td>#<b>{{ item.Folio }}</b></td>
                                                                    <td>{{ $getFormatTextDate(item.created_at,false) }}</td>
                                                                    <td>
                                                                        <span class="badge text-bg-primary" v-if="item.Estatus=='Pendiente'">{{item.Estatus}}</span>
                                                                        <span class="badge text-bg-info"    v-if="item.Estatus=='Pagado'">{{item.Estatus}}</span>
                                                                        <span class="badge text-bg-warning" v-if="item.Estatus=='Procesando'">{{item.Estatus}}</span>
                                                                        <span class="badge text-bg-success" v-if="item.Estatus=='Completado'">{{item.Estatus}}</span>
                                                                        <span class="badge text-bg-danger"  v-if="item.Estatus=='Cancelado'">{{item.Estatus}}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span class="badge text-bg-warning" v-if="item.EstatusPago=='Pendiente'">{{item.EstatusPago}}</span>
                                                                        <span class="badge text-bg-success" v-if="item.EstatusPago=='Pagado'">{{item.EstatusPago}}</span>
                                                                        <span class="badge text-bg-danger"  v-if="item.EstatusPago=='Cancelado'">{{item.EstatusPago}}</span>
                                                                    </td>
                                                                    <td class="text-end"><b>{{ $formatNumber(item.GranTotal,'$') }}</b></td>
                                                                    <td><a @click="goDetallePedido(item)" style="cursor: pointer;" class="btn-small d-block text-morado">Ver Detalle</a></td>
                                                                </tr>
                                                                <CSinRegistros :pContIF="listaPedidos.length" :pColspan="6"></CSinRegistros>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </template>
                                                </CLoader>
                                                
                                            </div>
                                        </div>
                                        <div class="row">
                                            <CPagina :Filtro="FiltroPedidos" @Pagina="getPedidos(2)" ></CPagina>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- DIRECCIONES DE ENVIO -->
                                <div class="tab-pane fade" id="tab-contenido-3" role="tabpanel" aria-labelledby="address-tab">
                                    <div class="card">
                                        <div class="card-header">
                                            <h3 class="mb-0">Tus direcciones para envios</h3>
                                        </div>
                                    </div>
                                    
                                    <div v-if="!editInformacion" class="row row-cols-1 row-cols-md-2 g-4">
                                     
                                        <div v-for="(item,idex) in listDireccionesEnvio" :key="idex" class="col">
                                            <div class="card h-100">
                                                <div class="card-body">
                                                    <div class="product-added">
                                                        <div class="details">
                                                            <h3 class="name"> 
                                                                <i v-if="item.TipoDireccion == 'Trabajo'" class="fal fa-suitcase fa-lg"></i>
                                                                <i v-if="item.TipoDireccion == 'Casa'" class="fal fa-house fa-lg"></i>
                                                                {{ item.Nombre }} </h3>
                                                            <p class="size-color">Calle {{ item.Calle }}, NoExt. {{ item.NoExt }}, 
                                                            <span v-if="item.NoInt != undefined && item.NoInt != null">No Int.{{ item.NoInt }}</span> Cruzamientos: {{ item.Cruzamiento1 }} 
                                                            <span v-if="item.Cruzamiento2 != undefined && item.Cruzamiento2 != null">y {{ item.Cruzamiento2 }},</span> Colonia: {{ item.Colonia }},
                                                            CP: {{ item.CodigoPostal }}, {{ item.Municipio }}, {{ item.Estado }}, {{ item.Pais }} 
                                                            <br> Referencias: {{ item.Referencias }}
                                                            <br>Telefono: {{ item.Telefono }}
                                                            </p>
                                                            
                                                            <p class="price"></p>
                                                            <div class="cantidad">
                                                                <div class="d-flex align-items-center me-1">
                                                                    <a class="text-black ms-4" style="cursor: pointer;" @click="eliminaDireccion(item)"><i class="fas fa-trash-alt"></i> Eliminar</a>
                                                                    <a class="text-morado" style="cursor: pointer; margin-left: 9rem !important;" @click="recoveryDireccion(item)"><i class="fas fa-pencil-alt"></i> Editar</a>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        
                                    </div>
                                    <div v-if="!editInformacion" class="row">
                                        <div class="col-12 mt-5">
                                
                                            <div v-if="listDireccionesEnvio.length == 0" class="text-center mt-4">
                                                <h3 class="text-morado">No cuenta con direcciones para envio!</h3>
                                                <h6>¿Desea <span class="text-morado" style="cursor: pointer;" @click="addDireccion()"><u>agregar</u></span> una nueva dirección?</h6>
                                                
                                            </div>
                                            <div v-else-if="listDireccionesEnvio.length < 4" class="d-flex align-items-center me-1 mb-4">
                                                <a class="text-morado" style="cursor: pointer;" @click="addDireccion()">Agregar una nueva dirección</a>
                                            </div>
                                        
                                        </div>   
                                    </div>

                                    <div v-if="editInformacion" class="col-12 col-md-12 col-lg-12">
                                        <CLoader :pConfigLoad="ConfigLoadForm">
                                            <template slot="BodyFormLoad">
                                                <div class="container-fluid">
                                                    <form id="FormClientedireccionenvio" role="form" method="post" autocomplete="off" onSubmit="return false">
                                                        
                                                        
                                                        <div class="form-group row">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                                <label class="form-label" for="Nombre">Nombre</label>
                                                                <input type="text" v-model="objClientedireccionenvio.Nombre" class="form-control" id="Nombre" placeholder="Ingresar Nombre" />
                                                                <CValidation v-if="this.errorvalidacion.Nombre" :Mensaje="'*'+errorvalidacion.Nombre[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                                <label class="form-label" for="TipoDireccion">Tipo Direccion</label>
                                                                <br>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Casa" v-model="objClientedireccionenvio.TipoDireccion">
                                                                    <label class="form-check-label" for="inlineRadio1"><i class="fal fa-house fa-lg"></i> Casa</label>
                                                                </div>
                                                                
                                                        
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Trabajo" v-model="objClientedireccionenvio.TipoDireccion">
                                                                    <label class="form-check-label" for="inlineRadio2"><i class="fal fa-suitcase fa-lg"></i> Trabajo</label>
                                                                </div>

                                                                <CValidation v-if="this.errorvalidacion.TipoDireccion" :Mensaje="'*'+errorvalidacion.TipoDireccion[0]"/>
                                                            </div>

                                                        </div>
                                                        
                                                        <div class="form-group row mt-2">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                                                <label class="form-label" for="Calle">Calle</label>
                                                                <input type="text" v-model="objClientedireccionenvio.Calle" class="form-control" id="Calle" placeholder="Ingresar Calle" />
                                                                <CValidation v-if="this.errorvalidacion.Calle" :Mensaje="'*'+errorvalidacion.Calle[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                                                <label class="form-label" for="NoExt">No Ext</label>
                                                                <input type="text" v-model="objClientedireccionenvio.NoExt" class="form-control" id="NoExt" placeholder="Ingresar NoExt" />
                                                                <CValidation v-if="this.errorvalidacion.NoExt" :Mensaje="'*'+errorvalidacion.NoExt[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                                                <label class="form-label" for="NoInt">No Int</label>
                                                                <input type="text" v-model="objClientedireccionenvio.NoInt" class="form-control" id="NoInt" placeholder="Ingresar NoInt" />
                                                                <CValidation v-if="this.errorvalidacion.NoInt" :Mensaje="'*'+errorvalidacion.NoInt[0]"/>
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                        <div class="form-group row mt-2">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                                <label class="form-label" for="Cruzamiento1">Cruzamiento 1</label>
                                                                <input type="text" v-model="objClientedireccionenvio.Cruzamiento1" class="form-control" id="Cruzamiento1" placeholder="Ingresar Cruzamiento1" />
                                                                <CValidation v-if="this.errorvalidacion.Cruzamiento1" :Mensaje="'*'+errorvalidacion.Cruzamiento1[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                                <label class="form-label" for="Cruzamiento2">Cruzamiento 2</label>
                                                                <input type="text" v-model="objClientedireccionenvio.Cruzamiento2" class="form-control" id="Cruzamiento2" placeholder="Ingresar Cruzamiento2" />
                                                                <CValidation v-if="this.errorvalidacion.Cruzamiento2" :Mensaje="'*'+errorvalidacion.Cruzamiento2[0]"/>
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                        <div class="form-group row mt-2">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                                                <label class="form-label" for="Colonia">Colonia</label>
                                                                <input type="text" v-model="objClientedireccionenvio.Colonia" class="form-control" id="Colonia" placeholder="Ingresar Colonia" />
                                                                <CValidation v-if="this.errorvalidacion.Colonia" :Mensaje="'*'+errorvalidacion.Colonia[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                                                <label class="form-label" for="CodigoPostal">CodigoPostal</label>
                                                                <input type="text" v-model="objClientedireccionenvio.CodigoPostal" class="form-control" id="CodigoPostal" placeholder="Ingresar CodigoPostal" />
                                                                <CValidation v-if="this.errorvalidacion.CodigoPostal" :Mensaje="'*'+errorvalidacion.CodigoPostal[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                                                <label class="form-label" for="Municipio">Municipio</label>
                                                                <input type="text" v-model="objClientedireccionenvio.Municipio" class="form-control" id="Municipio" placeholder="Ingresar Municipio" />
                                                                <CValidation v-if="this.errorvalidacion.Municipio" :Mensaje="'*'+errorvalidacion.Municipio[0]"/>
                                                            </div>
                                                        </div>
                                                        
                                                    
                                                        
                                                        <div class="form-group row mt-2">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                                                <label class="form-label" for="Estado">Estado</label>
                                                                <input type="text" v-model="objClientedireccionenvio.Estado" class="form-control" id="Estado" placeholder="Ingresar Estado" />
                                                                <CValidation v-if="this.errorvalidacion.Estado" :Mensaje="'*'+errorvalidacion.Estado[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                                                <label class="form-label" for="Pais">Pais</label>
                                                                <input type="text" v-model="objClientedireccionenvio.Pais" class="form-control" id="Pais" placeholder="Ingresar Pais" />
                                                                <CValidation v-if="this.errorvalidacion.Pais" :Mensaje="'*'+errorvalidacion.Pais[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                                                <label class="form-label" for="Telefono">Telefono</label>
                                                                <input type="text" v-model="objClientedireccionenvio.Telefono" class="form-control" id="Telefono" placeholder="Ingresar Telefono" />
                                                                <CValidation v-if="this.errorvalidacion.Telefono" :Mensaje="'*'+errorvalidacion.Telefono[0]"/>
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                        <div class="form-group row mt-2">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <label class="form-label" for="Referencias">Referencias</label>
                                                                <textarea v-model="objClientedireccionenvio.Referencias" class="form-control" rows="3" id="Referencias" placeholder="Ingresar Referencias" />
                                                                <CValidation v-if="this.errorvalidacion.Referencias" :Mensaje="'*'+errorvalidacion.Referencias[0]"/>
                                                            </div>
                                                        </div>
                                                        
                                                        <br>
                                                        <CBtnSave :poBtnSave="oBtnSave" />

                                                        <br>
                                                    </form>
                                                </div>
                                            </template>
                                        </CLoader>
                                    </div>
                                </div>

                                <!-- DATOS FICALES -->
                                <div class="tab-pane fade" id="tab-contenido-4" role="tabpanel" aria-labelledby="fiscal-detail-tab">
                                    <FormDatosFiscales v-if="tab4" :pEmitSeccion="Emit"/>
                                </div>

                                <!-- DATOS DE CUENTA -->
                                <div class="tab-pane fade" id="tab-contenido-5" role="tabpanel" aria-labelledby="account-detail-tab">
                                    <FormDatosCuenta v-if="tab5" :pEmitSeccion="Emit2" />
                                </div>

                                <div class="tab-pane fade" id="tab-contenido-6" role="tabpanel" aria-labelledby="memberzin-detail-tab">
                                   <FormConectZinAccountVue v-if="tab6" :pVerificacion="verificacion" :pEmitSeccion="Emit3"/>
                                </div>

                                <!-- LISTA DE EVENTOS COMPRADOS -->
                                <div class="tab-pane fade" id="tab-contenido-7" role="tabpanel" aria-labelledby="eventos-tab">
                                    <div class="card" v-if="viewListEvents">
                                        <div class="card-header">
                                            <h3 class="mb-0">Eventos</h3>
                                        </div>
                                        <div class="card-body">
                                            

                                                <CLoader :pConfigLoad="ConfigLoadEventos">
				                                    <template slot="BodyFormLoad">



                                                        <div class="row row-cols-1 row-cols-md-3 g-4">
                                                            <div v-for="(item3,index3) in ListaClienteEventos" :key="index3" class="col">
                                                                <div class="card" style="cursor: pointer;" @click="ViewEventoDetalle(item3)">
                                                                    <img :src="rutaEventos+item3.IdEvento+'/'+item3.evento.LogoEvento" class="card-img-top" alt="...">
                                                                    <div class="card-body">
                                                                        <h5 class="card-title" >{{ item3.evento.NomEvento }}</h5>
                                                                        <p class="card-text"><i class="fas fa-calendar-alt"></i> {{ $getCleanDate(item3.evento.FechaEvento,false) }}</p>
                                                                        <p class="card-text">
                                                                            <span class="badge text-bg-warning" v-if="item3.OpEstatusTransaccion=='charge_pending'">Pago Pendiente</span>
                                                                            <span class="badge text-bg-success" v-if="item3.OpEstatusTransaccion=='completed'">Pagado</span>
                                                                            <span class="badge text-bg-danger"  v-if="item3.OpEstatusTransaccion=='NoEnviada'">Problema con el Pago</span>
                                                                        </p>
                                                                        <p class="card-text">{{ $limitCharacters(item3.evento.Descripcion,90) }}</p>
                                                                    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                    </template>
                                                </CLoader>
                                                
                                            
                                        </div>
                                        <div class="row">
                                            <CPagina :Filtro="FiltroEventos" @Pagina="getEventos(2)" ></CPagina>
                                        </div>
                                    </div>
                                    <div v-if="viewListEvents == false" class="card">
                                        <div class="card-header">
                                            <h3 class="mb-0">Eventos</h3>
                                        </div>
                                        <div class="card-body">
                                            
                                            <div class="container container-width">

                                                <div class="row justify-content-center">

                                                    
                                                    
                                                    <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 mb-4">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <figure>
                                                                    <img :src="rutaEventos+tmpEvento.IdEvento+'/'+tmpEvento.evento.Imagen" alt="" class="img-fluid rounded">
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 mt-3">
                                                        <h2 class="mb-10"> {{ tmpEvento.evento.NomEvento }}</h2>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 mb-4">
                                                        <div class="single-header-meta">
                                                            <div class="entry-meta meta-1 font-xs mt-15 mb-15">
                                                               <h6 class="text-secondary"> <i class="fas fa-calendar-alt"></i> Fecha: <span class="text-morado">{{ $getCleanDate(tmpEvento.evento.FechaEvento, false) }}</span>&nbsp;</h6>
                                                            </div>
                                                        </div>
                                                        <div class="single-header-meta">
                                                            <div class="entry-meta meta-1 font-xs mt-15 mb-15">
                                                                <h6 class="text-secondary"><i class="fas fa-ticket-alt"></i>  {{tmpEvento.acceso.NomAcceso}} por <span class="text-morado">{{ tmpEvento.OpCurrency }}  {{ $formatNumber(tmpEvento.Total,'$') }}  </span>&nbsp;</h6>
                                                            </div>
                                                        </div>
                                                        <hr>
                                                        <div class="single-header-meta">
                                                            <div class="entry-meta meta-1 font-xs mt-15 mb-15">
                                                                <h6 class="text-secondary"><i class="fas fa-user"></i> {{tmpEvento.CNombre+' '+tmpEvento.CApellido}}&nbsp;</h6>
                                                            </div>
                                                        </div>
                                                        <div class="single-header-meta">
                                                            <div class="entry-meta meta-1 font-xs mt-15 mb-15">
                                                                <h6 class="text-secondary"><i class="fas fa-file-invoice"></i> Orden #<span class="text-morado">{{ tmpEvento.IdVentaEvento }}  </span>&nbsp;</h6>
                                                            </div>
                                                        </div>
                                                        <div class="single-header-meta">
                                                            <div class="entry-meta meta-1 font-xs mt-15 mb-15">
                                                                <h6 class="text-secondary">
                                                                    <i v-if="tmpEvento.OpMetodo == 'card'" class="fad fa-credit-card"></i>
                                                                    <i v-if="tmpEvento.OpMetodo == 'transfer'" class="fad fa-university"></i>&nbsp;
                                                                        <span class="badge text-bg-warning" v-if="tmpEvento.OpEstatusTransaccion=='charge_pending'">Pago Pendiente</span>
                                                                        <span class="badge text-bg-success" v-if="tmpEvento.OpEstatusTransaccion=='completed'">Pagado</span>
                                                                        <span class="badge text-bg-danger"  v-if="tmpEvento.OpEstatusTransaccion=='NoEnviada'">Problema con el Pago</span>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 mb-4">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-html="tmpEvento.evento.Actividades"></div>
                                                        </div>
                                                    </div>

                                                </div>

                                                

                                            </div>
        
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

   
    
    
  </main>
</template>

<script>
import CPagina from '@/components/CPagina.vue';
import Configs from '@/helpers/VarConfig';
import CLoader from '@/components/CLoader.vue';
import CBtnSave from '@/components/CBtnSave.vue';
import FormDatosFiscales from './componentes/FormDatosFiscales.vue';
import FormDatosCuenta from './componentes/FormDatosCuenta.vue';
import CSinRegistros from '../../components/CSinRegistros.vue';
import FormConectZinAccountVue from './componentes/FormConectZinAccount.vue';

const EmitSeccion = 'SeccionPefilDirCliente';
const EmitSeccion2 = 'SeccionPefilDatosCuenta';
const EmitSeccion3 = 'SeccionPefilCuentaZin';

export default {
    name: 'ViewCliente',
    props:['pOrigen'],
    components: {
        CPagina,
        CLoader,
        CBtnSave,
        FormDatosFiscales,
        FormDatosCuenta,
        CSinRegistros,
        FormConectZinAccountVue
    },

    data() {
        return {
            Emit:EmitSeccion,
            Emit2:EmitSeccion2,
            Emit3:EmitSeccion3,
            usuario:{
                IdUsuario:0,
                IdPerfil:0,
                IdPuesto:0,
                IdCliente:0,
                Nombre:"",
                Apellido:"",
                NombreCompleto:"",
                Telefono:"",
                email:"",
                Imagen:"",
                TipoUser:"",
                EstatusMem:""
            },
            ConfigLoadPedidos:{
                ShowLoader: true,
                ClassLoad: false,
            },
            FiltroPedidos: {
                Nombre: '',
                Pagina: 1,
                Entrada: 10,
                TotalItem: 0,
                Placeholder: 'Buscar..',
                ArrayEntradas: ['30','60','90','120']
                /*Anio: moment(new Date()).format('YYYY'),*/
            },
            listaPedidos:[],

            // DIRECCIONES
            ConfigLoadDirecciones:{
                ShowLoader: true,
                ClassLoad: false,
            },
            ConfigLoadForm:{
                ShowLoader: true,
                ClassLoad: false,
            },
            listDireccionesEnvio: [],
            editInformacion: false,
            oBtnSave:{
                Toast: 0,
                IsModal: false,
                DisableBtn: false,
                ActiveEmitReturn: true,
                ReturnRoute: '',
                EmitSeccion: EmitSeccion,
            },

            objClientedireccionenvio: {
				IdCliDireccionEnvio: 0,
				IdCliente: '',
				TipoDireccion: '',
				Nombre: '',
				Calle: '',
				NoExt: '',
				NoInt: '',
				Cruzamiento1: '',
				Cruzamiento2: '',
				Colonia: '',
				CodigoPostal: '',
				Estado: '',
				Municipio: '',
				Pais: '',
				Telefono: '',
				Referencias: '',
				RfcCliente: '',
            },
            errorvalidacion: [],

            tab1: false,
            tab2: false,
            tab3: false,
            tab4: false,
            tab5: false,
            tab6: false,
            tab7: false,

            // VERIFICACION DE MEMBRESIAS
            verificacion:{
                codeZin:'',
                stateCode:''
            },

            // LISTA EVENTOS
            viewListEvents: true,
            ConfigLoadEventos:{
                ShowLoader: true,
                ClassLoad: false,
            },
            FiltroEventos: {
                Nombre: '',
                Pagina: 1,
                Entrada: 10,
                TotalItem: 0,
                Placeholder: 'Buscar..',
                ArrayEntradas: ['30','60','90','120']
                /*Anio: moment(new Date()).format('YYYY'),*/
            },
            ListaClienteEventos:[], // clienteseventos
            rutaEventos:'',
            tmpEvento: {
                evento: {

                },
                acceso:{
                    
                }
            }
        };
    },

    created() {
        this.bus.$off('Save_'+EmitSeccion);
        this.bus.$off('EmitReturn');

        // LIMPIEZA Y OBTENCION DE PARAMETROS DE URL PARA MEMBRESIAS ZIN
        this.cleanDataZin();
        this.verificacion.codeZin   = this.$getParameterByName('code');
        this.verificacion.stateCode = this.$getParameterByName('state');


    },
    mounted() {
        this.bus.$emit('cambiaLogoNegro');
        window.scrollTo(0, 0);

        if(this.pOrigen != undefined && this.pOrigen != null) {
            switch (this.pOrigen) {
                case 'detPedido':
                    this.getPedidos();
                    break;

                case 'pagoEvento':
                    this.getEventos();
                    break;

                default:
                    this.showTab(1);
                    break;
            }
        }else {
            this.showTab(1);
        }

        if(this.verificacion.codeZin !== ''){
            this.showTab(6);
            console.log(this.verificacion);
        }


        if(localStorage.getItem('user') != undefined && localStorage.getItem('user') != null) {
            this.usuario = JSON.parse(localStorage.getItem('user'));
        }


        this.bus.$on('Save_'+EmitSeccion,()=>
        {
            this.GuardarDireccion();
        });

        this.bus.$on('EmitReturn',()=>
        {
            this.verListDirecciones();
        });

        
    },

    methods: {

        /*************** PEDIDOS ***************/
        async getPedidos(op = 1) {
            if(op == 1){
                this.showTab(2);
            }
            
            this.ConfigLoadPedidos.ShowLoader = true;

            await this.$http.get("pedidos",{
                params:{
                    TxtBusqueda: this.FiltroPedidos.Nombre,
                    Entrada: this.FiltroPedidos.Entrada,
                    Pag: this.FiltroPedidos.Pagina,
                }
            }).then((res) => {
                this.FiltroPedidos.Pagina      = res.data.data.current_page;
                this.FiltroPedidos.TotalItem   = res.data.data.total;
                this.listaPedidos              = res.data.data.data;
                
            }).finally(()=>{
                this.ConfigLoadPedidos.ShowLoader = false;
            });

            
        },

        /*************** SESION ***************/
        cerrarSesion() {
            this.$swal(Configs.configCerrarSession)
            .then((result) => {
                if(result.value)
                {   
                    this.$http.get('close').then( (res) => {
                        console.log('goodbye OK!');
                        this.$close_session();
                        this.$toast.info('Haz cerrado tu session');
                        this.goHome();  
                    }).catch( err => {
                        console.log(err);
                    });

                    
                    
                } 
            }).finally(() => {
                
            });

		},

        /*************** DIRECCIONES DE ENVIO ***************/
        addDireccion() {
            this.editInformacion = true;
            this.LimpiarDireciones();
            this.ConfigLoadForm.ShowLoader = false;
            window.scrollTo(0, 0);
        },

        verListDirecciones() {
            this.showTab(3);
            this.LimpiarDireciones();
            this.editInformacion = false;
            window.scrollTo(0, 0);
            this.getDireccionesEnvio();
        },

        getDireccionesEnvio() {
            //this.ConfigLoadDirecciones.ShowLoader = true;
            this.$http.get('clientesdireccionesenvios', {
                params:{
                    Entrada: 8,
                    Pag: 1
                }

            }).then( (res) => {
                this.listDireccionesEnvio 			= res.data.data.data;

            }).catch( err => {
                if(err.response.data.errors){
                    this.errorvalidacion=err.response.data.errors;
                }else{
                    this.$toast.error(err.response.data.message);
                }

            }).finally(() => {
                //this.ConfigLoadDirecciones.ShowLoader = false;
            })
        },

        LimpiarDireciones()
        {
            this.objClientedireccionenvio = {
				IdCliDireccionEnvio: 0,
				IdCliente: '',
				TipoDireccion: '',
				Nombre: '',
				Calle: '',
				NoExt: '',
				NoInt: '',
				Cruzamiento1: '',
				Cruzamiento2: '',
				Colonia: '',
				CodigoPostal: '',
				Estado: '',
				Municipio: '',
				Pais: '',
				Telefono: '',
				Referencias: '',
				RfcCliente: '',
				
            }
            this.errorvalidacion = [];
        },

        recoveryDireccion(item) {
            this.editInformacion = true;
            this.ConfigLoadForm.ShowLoader = true;

            this.$http.get('clientesdireccionesenvios/'+item.IdCliDireccionEnvio)
            .then( (res) => {
                this.objClientedireccionenvio  = res.data.data;
               
            }).finally(()=>{
                this.ConfigLoadForm.ShowLoader = false;
            });
        },

        eliminaDireccion(item) {
            
            this.$swal(Configs.configEliminar).then((result) =>
            {
                if(result.value)
                {
                    this.$http.delete('clientesdireccionesenvios/'+item.IdCliDireccionEnvio
                    ).then((res) => {
                        this.$swal(Configs.configEliminarConfirm);
                        this.verListDirecciones();
                    }).catch(err => {
                        this.$toast.error(err.response.data.message);
                    });
                } 
            });

        },

        async GuardarDireccion()
        {
			this.errorvalidacion = [];
			this.oBtnSave.Toast = 0; 
			this.oBtnSave.DisableBtn = true;

			if(this.objClientedireccionenvio.IdCliDireccionEnvio == 0) {
                let temp =  JSON.parse(localStorage.getItem('user'));
                this.objClientedireccionenvio.IdCliente = temp.IdCliente;

				await this.$http.post(
					'clientesdireccionesenvios',this.objClientedireccionenvio
				).then((res)=>{
					this.EjecutaConExito(res);
				}).catch(err=>{   
					this.EjecutaConError(err);
				});
			} else {
				await this.$http.put(
					'clientesdireccionesenvios/'+this.objClientedireccionenvio.IdCliDireccionEnvio,
					this.objClientedireccionenvio
				).then((res)=>{                 
					this.EjecutaConExito(res);
				}).catch(err=>{   
					this.EjecutaConError(err);
				});
			}
        },
		EjecutaConExito(res)
        {
            this.oBtnSave.DisableBtn = false;  
            this.$toast.success("Información guardada");
            this.verListDirecciones();
        },

        EjecutaConError(err)
        {
            this.oBtnSave.DisableBtn = false;
            
            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.oBtnSave.Toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },

        // MEMBRESIA ZIN
        cleanDataZin(){
            this.verificacion = {
                codeZin: '',
                stateCode: ''
            }
        },

        // EVENTOS
        /*************** PEDIDOS ***************/
        async getEventos(op = 1) {
            this.viewListEvents = true;

            if(op == 1){
                this.showTab(7);
            }
            
            this.ConfigLoadEventos.ShowLoader = true;

            await this.$http.get("clienteseventos",{
                params:{
                    TxtBusqueda: this.FiltroEventos.Nombre,
                    Entrada: this.FiltroEventos.Entrada,
                    Pag: this.FiltroEventos.Pagina,
                }
            }).then((res) => {
                this.FiltroEventos.Pagina      = res.data.data.current_page;
                this.FiltroEventos.TotalItem   = res.data.data.total;
                this.ListaClienteEventos       = res.data.data.data;
                this.rutaEventos               = res.data.rutaFile;
                
            }).finally(()=>{
                this.ConfigLoadEventos.ShowLoader = false;
            });

            
        },

        ViewEventoDetalle(item){
            this.viewListEvents = false;
            this.tmpEvento = item;
        },

        // CONTROL TABS
        showTab(item) {

            // APAGAMOS TODAS LAS TABS
            for (let step = 0; step < 7; step++) {
                var btnActivador = document.getElementById("tab-"+(step+1));
            
                if(btnActivador.classList.contains('active')) {
                    btnActivador.classList.remove("active");
                }
                
                var contenedor = document.getElementById("tab-contenido-"+(step+1));
                if(contenedor.classList.contains('active')) {
                    contenedor.classList.remove("active");
                }
                if(contenedor.classList.contains('show')) {
                    contenedor.classList.remove("show");
                }
                
            }

            //UBICAMOS Y ACTIVAMOS LA INDICADA
            for (let step = 0; step < 7; step++) {

                if(item == (step+1)) {
                    this.activateComponents(item)
                    var btnActivador = document.getElementById("tab-"+(step+1));
                    btnActivador.classList.add("active");

                    var contenedor = document.getElementById("tab-contenido-"+(step+1));
                    contenedor.classList.add("active");
                    contenedor.classList.add("show");
                }  
                
            }
        },

        activateComponents(i) {
            this.tab1 = false;
            this.tab2 = false;
            this.tab3 = false;
            this.tab4 = false;
            this.tab5 = false;
            this.tab6 = false;
            this.tab7 = false;
            
            switch (parseInt(i)) {
                case 1:
                    this.tab1 = true;
                    break;
                case 2:
                    this.tab2 = true;
                    break;
                case 3:
                    this.tab3 = true;
                    break;
                case 4:
                    this.tab4 = true;
                    break;
                case 5:
                    this.tab5 = true;
                    break;
                case 6:
                    this.tab6 = true;
                    break;
                case 7:
                    this.tab7 = true;
                    break;
            }
        },
        
        // UTILIDADES
        goHome(){
            this.$router.push({name:'principal',params:{} });
        },   
        
        goDetallePedido(item){
            this.$router.push({name:'pedidodetalles',params:{Id:item.IdPedido} });
        },
    },
};
</script>