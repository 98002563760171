<template>
    <div>
        <main style="margin-top: 92px;">
            <div class="container path pt-3 mb-4">
                <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a class="text-decoration-none text-morado fw-medium" @click="goHome()" style="cursor: pointer;">
                                <i class="fas fa-home-alt text-morado"></i> Inicio</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="text-decoration-none text-morado fw-medium" @click="Regresar()" style="cursor: pointer;"> Eventos</a>
                        </li>
                        <li class="breadcrumb-item active fw-medium" aria-current="page">{{ objEvento.NomEvento }}</li>
                    </ol>
                </nav>
            </div>
            <hr>

            <section>
                <div class="container container-width">

                    <div class="row justify-content-center">

                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 mt-3">
                            <h2 class="mb-10">{{ objEvento.NomEvento }}</h2>
                        </div>

                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 mb-4">
                            <div class="single-header-meta">
                                <div class="entry-meta meta-1 font-xs mt-15 mb-15">
                                    <h6 class="text-secondary"> Fecha: <span class="text-morado">{{ $getCleanDate(objEvento.FechaEvento, false) }}</span>&nbsp;</h6>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 mb-4">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <figure>
                                        <img :src="objEvento.rutaImgs+objEvento.IdEvento+'/'+objEvento.Imagen" alt="" class="img-fluid rounded">
                                    </figure>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 mb-4">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-html="objEvento.Actividades"></div>
                            </div>
                        </div>

                    </div>

                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 mt-3">
                            <div class="row text-end">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <button type="button" class="btn btn-primary btn-lg" @click="comprarTikets()">
                                        <i class="far fa-shopping-bag me-2"></i> Comprar Tickets 
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>

                </div>
            </section>
        </main>

        <ProductosSugeridos v-if="!ConfigLoad.ShowLoader" :IdCategoria="5" :pProducto="1" :pTituloSeccion="'También Podrían Interesarte'"/>

    </div>
</template>

<script>
import ProductosSugeridos from '@/views/catalogos/productos/ProductosSugeridos.vue';

export default {
    name: 'viewEventoDetalle',
    props:['pEvento'],
    components: {
        ProductosSugeridos
    },

    data() {
        return {
            objEvento:{
                IdEvento: 0,
                NomEvento: "",
                Descripcion: "",
                Actividades: "",
                FechaEvento: "",
                IdCreador: 0,
                Estatus: "",
                Activo: "",
                Aforo: 0,
                SoloZin: 0,
                MaxAccesoPorUsuario: 0,
                LogoEvento: "",
                Imagen: "",
                ArchivoTerminosCondiciones: "",
                rutaImgs:''
            },

            ConfigLoad:{
                ShowLoader: false,
                ClassLoad:  true,
            },
        };
    },

    mounted() {
        this.bus.$emit('cambiaLogoNegro');
        window.scrollTo(0,0);

        if(this.pEvento != undefined && this.pEvento != null) {
            this.objEvento =  this.pEvento;

        }else {
            if( sessionStorage.getItem('infoGeneral') != undefined && sessionStorage.getItem('infoGeneral') != null && sessionStorage.getItem('infoGeneral') != '' ) {
                this.objEvento = JSON.parse(sessionStorage.getItem('infoGeneral'));
            }
        }
    },

    methods: {
        Regresar() {
            this.$router.push({name: 'eventos', params:{}});
        },

        comprarTikets() {
            this.$router.push({name: 'compratickets', params:{pEvento:this.objEvento}});
        },
    },
};
</script>